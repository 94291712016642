jQuery( $ => {
// waypoints
	$( document )
		.ready( function() {
			$( '.wp0' )
				.waypoint( function() {
					$( '.wp0' )
						.addClass( 'animated fadeIn' );
				}, {
					offset: '75%',
				} );
			$( '.wp1' )
				.waypoint( function() {
					$( '.wp1' )
						.addClass( 'animated fadeInUp' );
				}, {
					offset: '75%',
				} );
			$( '.wp2' )
				.waypoint( function() {
					$( '.wp2' )
						.addClass( 'animated fadeInUp' );
				}, {
					offset: '75%',
				} );
			$( '.wp3' )
				.waypoint( function() {
					$( '.wp3' )
						.addClass( 'animated fadeInDown' );
				}, {
					offset: '55%',
				} );
			$( '.wp4' )
				.waypoint( function() {
					$( '.wp4' )
						.addClass( 'animated fadeInDown' );
				}, {
					offset: '75%',
				} );
			$( '.wp5' )
				.waypoint( function() {
					$( '.wp5' )
						.addClass( 'animated fadeInUp' );
				}, {
					offset: '75%',
				} );
			$( '.wp6' )
				.waypoint( function() {
					$( '.wp6' )
						.addClass( 'animated fadeInDown' );
				}, {
					offset: '75%',
				} );
		} );

	//slide in nav
	$( window )
		.load( function() {
			$( '.nav_slide_button' )
				.click( function() {
					$( '.pullslide' )
						.slideToggle();
				} );
		} );

	// Nav Transform icon
	document.querySelector( '#nav-toggle' )
		.addEventListener( 'click', function() {
			this.classList.toggle( 'active' );
		} );

	//parallax
	$( document )
		.ready( function() {
			const width = $( window )
				.width();
			if ( width > 1024 ) {
				const $window = $( window );
				$( 'section[data-type="background"]' )
					.each( function() {
						const $bgobj = $( this ); // assigning the object
						$( window )
							.scroll( function() {
								// Scroll the background at var speed
								// the yPos is a negative value because we're scrolling it UP!
								const yPos = -(
									$window.scrollTop() / $bgobj.data( 'speed' )
								);
								// Put together our final background position
								const coords = '50% ' + yPos + 'px';
								// Move the background
								$bgobj.css( { backgroundPosition: coords } );
							} ); // window scroll Ends
					} );
			}
		} );

	document.createElement( 'section' );

	$( document )
		.ready( function() {
			// Textslider
			const owl = $( '.owl-carousel' );
			owl.owlCarousel( {
				items: 1,
				loop: true,
				margin: 0,
				nav: false,
				dots: true,
				smartSpeed: 2500,
				autoplay: true,
				autoplayTimeout: 5000,
				// animateOut: 'fadeOut',
				autoplayHoverPause: false,
			} );
			$( '.play' )
				.on( 'click', function() {
					owl.trigger( 'autoplay.play.owl', [ 1000 ] );
				} );
			$( '.stop' )
				.on( 'click', function() {
					owl.trigger( 'autoplay.stop.owl' );
				} );

			//scrollTo
			$( function() {
				$( 'a[href*=#]:not([href=#])' )
					.click( function() {
						// eslint-disable-next-line no-undef
						if ( location.pathname.replace( /^\//, '' ) === this.pathname.replace( /^\//, '' ) && location.hostname === this.hostname ) {
							let target = $( this.hash );

							target = target.length ? target : $( '[name=' + this.hash.slice( 1 ) + ']' );
							if ( target.length ) {
								$( 'html,body' )
									.animate( {
										scrollTop: target.offset().top - 58,
									}, 500 );
								return false;
							}
						}
					} );
			} );

			// fade away objects on scroll with class .fader
			$( function() {
				const divs = $( '.fader' );
				$( window )
					.on( 'scroll', function() {
						const st = $( this )
							.scrollTop();
						divs.css( {
							opacity: 1 - ( st / 600 ),
						} );
					} );
			} );

			// add numbered class for iconbox
			$( '.iconbox' )
				.each( function( i ) {
					$( this )
						.addClass( 'item-' + ( i + 1 ) );
				} );
		} );

	//accordion
	$( document )
		.ready( function() {
			function closeAccordionSection() {
				$( '.accordion .accordion-section-title' )
					.removeClass( 'active' );
				$( '.accordion .accordion-section-content' )
					.slideUp( 300 )
					.removeClass( 'open' );
			}

			$( '.accordion-section-title' )
				.click( function( e ) {
					// Grab current anchor value
					const currentAttrValue = $( this )
						.attr( 'href' );

					if ( $( e.target )
						.is( '.active' ) ) {
						closeAccordionSection();
					} else {
						closeAccordionSection();

						// Add active class to section title
						$( this )
							.addClass( 'active' );
						// Open up the hidden content panel
						$( '.accordion ' + currentAttrValue )
							.slideDown( 300 )
							.addClass( 'open' );
					}
					e.preventDefault();
				} );

			// add numbered class for iconbox
			$( '.accordion-section-content' )
				.each( function( i ) {
					$( this )
						.addClass( 'item-' + ( i + 1 ) );
				} );
		} );
} );
